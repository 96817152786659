<template>
  <div>
    <a-row>
      <a-col :span="12"><h2>Clienti</h2></a-col>
      <a-col :span="12"><AddButton @click="$router.push('/client-nou')" style="float: right"/></a-col>
    </a-row>
    <a-table
        :row-key="(record) => record.id"
        :columns="columns"
        :data-source="clientStore.clients"
        :pagination="false"
        :scroll="{x: true}"
    >
      <template slot="name" slot-scope="text, record">
        <div :style="`background: ${record.color}; height: 10px; width: 10px; display: inline-block; margin-right: 10px`"></div>
        <router-link :to="`/client/${record.id}`">{{text}}</router-link>
      </template>
    </a-table>
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client-store";
import AddButton from "@/components/Buttons/AddButton";

const columns = [
  {
    title: 'Nume',
    dataIndex: 'name',
    key: 'name',
    fixed: true,
    scopedSlots: {
      customRender: "name"
    }
  },
  {
    title: 'Serie facturi',
    dataIndex: 'invoiceSeries',
    key: 'invoiceSeries',
  },
  {
    title: 'Telefon',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Tarif / Sedinta',
    dataIndex: 'fee',
    key: 'fee',
  },
  {
    title: 'Tara',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Judet',
    dataIndex: 'county',
    key: 'county',
  },
  {
    title: 'Oras',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Adresa',
    dataIndex: 'address',
    key: 'address',
  }
];

export default {
  name: 'Clients',
  components: {
    AddButton
  },
  setup () {
    const clientStore = useClientStore()

    // const handleCustomRow = (record) => {
    //   return {
    //     on: {
    //       click: () => {
    //         console.log(record)
    //       }
    //     }
    //   }
    // }

    return {
      clientStore,
      columns
    }
  },
  async mounted() {
    await this.clientStore.getClients()
  },
}
</script>

<style scoped>

</style>
