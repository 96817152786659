import { defineStore } from 'pinia'
import Vue from "vue";
import {validateResponse} from "@/utils";
import {createClient, deleteClient, getClient, getClients, updateClient} from "@/services/client-service";
import router from "@/router";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useClientStore = defineStore('client', {
    state: () => ({
        loading: false,
        clients: [],
        currentClient: {}
    }),
    actions: {
        async createClient(values) {
            try {
                this.loading = true
                const response = await createClient(values)
                validateResponse(response, 201)
                this.loading = false
                Vue.$toast.success("Clientul a fost adaugat cu succes");
                await router.push('/clienti')
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async getClients() {
            try {
                this.loading = true
                this.currentClient = {}
                console.log(localStorage.getItem('token'))
                const response = await getClients()
                const clientsResponse = validateResponse(response, 200)
                this.clients = clientsResponse.rows
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async getClient(id) {
            try {
                this.loading = true
                this.currentClient = {}
                const response = await getClient(id)
                this.currentClient = validateResponse(response, 200)
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async updateClient(id, values) {
            try {
                this.loading = true
                const response = await updateClient(id, values)
                this.currentClient = validateResponse(response, 200)
                this.loading = false
                Vue.$toast.success("Clientul a fost modificat cu succes");
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async deleteClient(id) {
            try {
                this.loading = true
                const response = await deleteClient(id)
                validateResponse(response, 204)
                this.loading = false
                Vue.$toast.success("Clientul a fost sters cu succes");
                await router.push('/clienti')
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
    }
})
