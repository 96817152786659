import {apiClient} from "./api-client.js";

export const createClient = (values) => apiClient({
    method: 'post',
    url: `/clients`,
    data: {
        ...values,
        invoiceSeries: values.invoiceSeries.toUpperCase()
    }
});

export const getClients = () => apiClient({
    method: 'get',
    url: `/clients`
});

export const getClient = (id) => apiClient({
    method: 'get',
    url: `/clients/${id}`
});

export const updateClient = (id, values) => apiClient({
    method: 'put',
    url: `/clients/${id}`,
    data: {
        ...values,
        invoiceSeries: values.invoiceSeries.toUpperCase()
    }
});

export const deleteClient = (id) => apiClient({
    method: 'delete',
    url: `/clients/${id}`
});
