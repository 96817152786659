import { render, staticRenderFns } from "./Clients.vue?vue&type=template&id=4abef7b3&scoped=true&"
import script from "./Clients.vue?vue&type=script&lang=js&"
export * from "./Clients.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4abef7b3",
  null
  
)

export default component.exports